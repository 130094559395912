<!--
  Leaderboards are stored in /ShivaLeaderboards/<leaderboardId>
  Entries are stored in /ShivaLeaderboards/<leaderboardId>/entries
  User selects a leaderboard to view
  A leaderboard entry contains:
    - playerNames, an array if player names
    - score
    - time
    - created
  Show all entries in a table that is sortable by score, time, and created
-->
<template>
  <div>
    <!-- select leaderboard -->
    <h2>Leaderboards</h2>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <div>Select Leaderboard:</div>
      <b-select v-model="selectedLeaderboard" @change="loadLeaderboard">
        <b-select-option v-for="leaderboard in leaderboards" :key="leaderboard.id" :value="leaderboard.id">{{
        leaderboard.id }}</b-select-option>
      </b-select>
      <button class="btn btn-secondary" @click="deleteLeaderboardEntries">Clear</button>
    </div>
    <b-table class="mt-2" striped hover :items="leaderboardEntries" :fields="[
        { key: 'playerNames', label: 'Players' },
        { key: 'score', label: 'Score', sortable: true },
        { key: 'time', label: 'Time', sortable: true },
        { key: 'created', label: 'Created', sortable: true }
      ]" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
      <template v-slot:cell(created)="data">
        {{ data.item.created?.toDate() | formatDate("YYYY-MM-DD HH:mm:ss") }}
      </template>
      <template v-slot:cell(playerNames)="data">
        {{ data.item.playerNames.join(', ') }}
      </template>
      <template v-slot:cell(time)="data">
        {{ parseFloat(data.item.time).toFixed(2) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('sget-game');
import { db } from '@/services/db';
import { deleteCollection } from '@/services/dbutils';

export default {
  data() {
    return {
      leaderboards: [],
      selectedLeaderboard: null,
      leaderboardEntries: [],
      sortBy: 'name',
      sortDesc: false
    }
  },
  async mounted() {
    log.log('mounted');
    await this.$bind("leaderboards", db.collection('ShivaLeaderboards'));
    log.log('leaderboards', this.leaderboards);
    if (this.leaderboards.length) {
      this.selectedLeaderboard = this.leaderboards[0].id;
      this.loadLeaderboard();
    }
  },
  methods: {
    async loadLeaderboard() {
      log.log('loadLeaderboard', this.selectedLeaderboard);
      this.$bind("leaderboardEntries", db.collection(`ShivaLeaderboards/${this.selectedLeaderboard}/entries`));
    },
    async deleteLeaderboardEntries() {
      log.log('deleteLeaderboard', this.selectedLeaderboard);
      if (await this.$bvModal.msgBoxConfirm(`Are you sure to delete all the entries of "${this.selectedLeaderboard}"?`, {centered: true}))
        await deleteCollection(db.collection(`ShivaLeaderboards/${this.selectedLeaderboard}/entries`));
    },
  }
}
</script>